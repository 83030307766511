import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18n from "../i18n";

export default function Home() {
  const { t } = useTranslation("overview");
  return (
    <div id="card_div">
      <div className="row">
        <div className="col-md-4">
          <div className="bg bg--light">
            <div className="card card--dark card--notification card--clickable">
              <div className="card__header">
                <h1 className="align-left text-h2 sc-navy">
                  {t("support-tools")}
                </h1>
              </div>
              <p className="card__text">{t("card-text")}</p>
              <Link
                to={"/" + i18n.language + "/DelistingForm"}
                className="link"
                id="delistinglink"
              >
                {t("card-link-text")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
