export class Enum {
  // Take any number of Arguments and set each of them as an attribute of the newly created Object.
  // ...keys is the rest-parameter syntax that provides all params as an array..
  constructor(...keys) {
    //.. so we can iterate over each of them.
    keys.forEach(key => {
      this[key] = key;
    });

    // Freeze it, so it cannot be changed anymore.
    Object.freeze(this);
  }

  // Make the attributes iterable, so they can easily used in a for-loop or using .next().
  // For more information, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol/iterator
  *[Symbol.iterator]() {
    for (let key of Object.keys(this)) yield key;
  }
}
