import { SUPPORTED_LANGUAGES } from "./constants";

// A help function that places the language code in the beginning of the path if it is missing
export default function resolvePathLanguage(path, userLang) {
  var pathParts = path.removeLeadingSlash().split("/");

  const pathHasValidLanguage = SUPPORTED_LANGUAGES.includes(pathParts[0]);

  if (!pathHasValidLanguage) {
    const userLanguageIsSupported = SUPPORTED_LANGUAGES.includes(userLang);
    pathParts = addLanguage(
      userLanguageIsSupported ? userLang : "en",
      pathParts
    );
  }

  return removeEmptyStringElements(pathParts).join("/");
}

String.prototype.removeLeadingSlash = function () {
  return this.replace(/^\/?/, "");
};

function addLanguage(language, pathParts) {
  return [language, ...pathParts];
}

function removeEmptyStringElements(array) {
  return array.filter((e) => e);
}
