import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import Home from "./Home.jsx";
import DelistingForm from "./DelistingForm.jsx";
import NotFound from "./NotFound.jsx";
import i18n from "../i18n";
import { SUPPORTED_LANGUAGES } from "../helpers/constants";

export default function SupportPage() {
  return (
    <div className="SupportPage">
      <Routes>
        <Route element={<NotFound />} />
        <Route path="/" element={<Home />}></Route>
        <Route path="/:lang/*" element={<LanguageWrapper />} />
        <Route path="/DelistingForm" element={<DelistingForm />} />
        <Route path="/provider/bounce/*" element={<DelistingForm />} />
      </Routes>
    </div>
  );
}

function LanguageWrapper() {
  const { lang } = useParams();

  const language = lang.toLowerCase();
  const languageParamIsValid = SUPPORTED_LANGUAGES.includes(language);

  // Set Language based on the URL (support.bluewin.ch/en/something) -> Language set to en
  useEffect(() => {
    async function setLanguage() {
      await i18n.changeLanguage(language);
    }
    if (languageParamIsValid) {
      setLanguage();
    }
  }, []);

  return (
    <Routes>
      {
        // To differentiate /not-found-url and /en
        languageParamIsValid ? (
          <Route path="/">
            <Route path="/" element={<Home />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/DelistingForm" element={<DelistingForm />} />
            {/* To support the links in the bounce emails */}
            <Route path="/provider/bounce/*" element={<DelistingForm />} />
          </Route>
        ) : (
          <Route path="*" element={<NotFound />} />
        )
      }
    </Routes>
  );
}
