import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React from "react";
import { withTranslation, Trans } from "react-i18next";
import { createValidationErrorMessage } from "../helpers/createValidationErrorMessage";
import { isEmailValid, isIPValid } from "../helpers/validator";
import getURLParameters from "../helpers/getURLParameters";
import { ShowHeaderNotification } from "../helpers/showHeaderNotification";
import {
  VIOLATIONS,
  PUBLIC_API_VERSION,
  FIELDNAMES,
} from "../helpers/constants";
import { containsHtmlTag } from "../helpers/htmlTagCheck";

class DelistingForm extends React.Component {
  constructor(props) {
    super(props);

    // Initialize all Refs to be able to get the values from the SDX-components and set their validationMessages.
    this.bounceHandlingConfirmationRef = React.createRef();
    this.doubleOptInconfirmationRef = React.createRef();
    this.gdprConfirmationRef = React.createRef();
    this.problemTypeRef = React.createRef();
    this.companyRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.ipRef = React.createRef();
    this.commentRef = React.createRef();
    this.declarationOfConsentRef = React.createRef();
    this.captchaValidationMessageRef = React.createRef();
    this.startDateRef = React.createRef();
    this.endDateRef = React.createRef();

    // Bind functions to "this" to make the class variables are accessible in the function.
    this.onStartDateInput = this.onStartDateInput.bind(this);
    this.enforceDateRangeConstraints =
      this.enforceDateRangeConstraints.bind(this);

    this.parameters = getURLParameters();
    this.openedFromBounceLink = false;

    document.title = `${props.t("contact-form")} | SupportPage | Bluewin`;

    // If Timestamp is present, it was called from bounce email Link
    if ("ts" in this.parameters) {
      this.openedFromBounceLink = true;
    }
  }

  onStartDateInput(event) {
    const startDate = new Date(event.target.value);

    if (this.endDateRef.value) {
      const endDate = new Date(this.endDateRef.value);
      this.enforceDateRangeConstraints(startDate, endDate);
    }

    document.getElementById("end-date-picker").flatpickrOptions = {
      minDate: startDate,
      maxDate: "today",
      dateFormat: "Y-m-d",
      enable: [startDate, startDate.fp_incr(1), startDate.fp_incr(2)],
    };
  }

  enforceDateRangeConstraints(startDate, endDate) {
    let latestAcceptableEndDate = new Date(startDate);
    latestAcceptableEndDate.setDate(startDate.getDate() + 2);
    if (endDate > latestAcceptableEndDate || endDate < startDate) {
      this.endDateRef.value = "";
    }
  }

  componentDidMount() {
    // Add all refs to Arrays so it is possible to iterate over both types to check their validity
    // and perform other actions.
    this.inputFieldRefs = [
      this.companyRef,
      this.lastNameRef,
      this.firstNameRef,
      this.emailRef,
      this.ipRef,
      this.commentRef,
    ];
    this.checkboxRefs = [
      this.bounceHandlingConfirmationRef,
      this.doubleOptInconfirmationRef,
      this.gdprConfirmationRef,
      this.declarationOfConsentRef,
    ];
    this.loadExternalCaptcha();
    this.setupValidators();
    this.handleParametersFromBounceEmailLink();

    if (!this.openedFromBounceLink) {
      this.inputFieldRefs.push(this.startDateRef);
      this.inputFieldRefs.push(this.endDateRef);

      document.getElementById("start-date-picker").flatpickrOptions = {
        maxDate: "today",
        dateFormat: "Y-m-d",
      };
      document.getElementById("end-date-picker").flatpickrOptions = {
        maxDate: "today",
        dateFormat: "Y-m-d",
      };
    }
  }

  // That's how it has to be done for the React + SDX combo, sadly.
  setupValidators() {
    this.ipRef.changeCallback = (ip) => {
      this.validateIP(ip);
    };

    this.emailRef.changeCallback = (email) => {
      this.validateEmail(email);
    };
  }

  validateEmail(email) {
    if (isEmailValid(email)) {
      this.emailRef.validationMessage = "";
    } else {
      this.emailRef.validationMessage = this.props.t(
        "notifications:invalid-email-address"
      );
    }
  }

  validateIP(ip) {
    if (isIPValid(ip)) {
      this.ipRef.validationMessage = "";
    } else {
      this.ipRef.validationMessage = this.props.t("notifications:invalid-ip");
    }
  }

  handleParametersFromBounceEmailLink() {
    if ("ip" in this.parameters) {
      this.ipRef.value = this.parameters.ip;
      this.ipRef.readonly = true;
    }
  }

  allFieldsAreCorrectlyFilledOut() {
    // If at least one input field has no content or a checkbox is not checked, return false.
    // False is also returned when reCAPTCHA is required but not response is present.
    // When this is the case, a validation message is added to the appropriate element.
    if (
      this.inputFieldRefs.some(
        (el) =>
          el.value === "" || el.value === undefined || containsHtmlTag(el.value)
      )
    ) {
      return false;
    }

    if (this.checkboxRefs.some((el) => !el.checked)) {
      return false;
    }

    if (document.getElementById("g-recaptcha-response")?.value === "") {
      return false;
    }

    if (!isEmailValid(this.emailRef.value)) {
      return false;
    }

    if (!isIPValid(this.ipRef.value)) {
      return false;
    }

    return true;
  }

  // Iterates through all inputs. If the required condition is not met, show the validation message.
  updateValidationMessages() {
    // Checkboxes
    for (var checkbox of this.checkboxRefs) {
      if (!checkbox.checked) {
        checkbox.validationMessage = this.props.t(
          "generalTerms:checkbox-required"
        );
      } else {
        checkbox.validationMessage = "";
      }
    }

    // Input Fields
    this.inputFieldRefs.forEach((inputField) => {
      if (inputField.value === "" || inputField.value === undefined) {
        inputField.validationMessage = this.props.t(
          "generalTerms:input-required"
        );
      } else if (containsHtmlTag(inputField.value)) {
        inputField.validationMessage = this.props.t(
          "generalTerms:html-tags-not-allowed"
        );
      } else {
        inputField.validationMessage = "";
      }
    });

    // Email (validates input as well)
    this.validateEmail(this.emailRef.value);

    // IP (validates input as well)
    this.validateIP(this.ipRef.value);

    // CAPTCHA
    if (document.getElementById("g-recaptcha-response")?.value === "") {
      this.captchaValidationMessageRef.classList.remove("hidden");
    } else {
      this.captchaValidationMessageRef.classList.add("hidden");
    }
  }

  // This is serves as an 'empty' callback-function to be used as a parameter.
  // Required because with null as a callback, the tests are failing in the pipeline.
  noFunction = () => {};

  sendForm() {
    this.updateValidationMessages();
    // Close existing notification if there is one present. This prevents having a success-notification
    // at the same time as a 'please fill out all fields' notification.
    this.notification?.close();

    if (!this.allFieldsAreCorrectlyFilledOut()) {
      this.notification = ShowHeaderNotification(
        this.props.t("notifications:fill-out-form-correctly"),
        "notification--alert"
      );
      return;
    }

    // Construct the element that is sent to the public-api.
    let objectToSend = Object.assign({
      bounceHandlingConfirmation: this.bounceHandlingConfirmationRef.checked,
      doubleOptInConfirmation: this.doubleOptInconfirmationRef.checked,
      gdprConfirmation: this.gdprConfirmationRef.checked,
      problemType: this.problemTypeRef.value?.[0],
      company: this.companyRef.value,
      lastName: this.lastNameRef.value,
      firstName: this.firstNameRef.value,
      email: this.emailRef.value,
      ip: this.ipRef.value,
      startDate: this.startDateRef?.value,
      endDate: this.endDateRef?.value,
      comment: this.commentRef.value,
      declarationOfConsent: this.declarationOfConsentRef.checked,
      captchaResponse: document.getElementById("g-recaptcha-response")?.value,
      timestamp: this.parameters?.ts,
      mailId: this.parameters?.id,
      scCode: this.parameters?.c,
    });

    // Add required HEADER-Options.
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": PUBLIC_API_VERSION,
        "Accept-Language": this.props.i18n.language,
        Accept: PUBLIC_API_VERSION,
      },
      body: JSON.stringify(objectToSend),
    };

    fetch(
      globalThis.ENVS.PUBLIC_API_BASE_URL + "/contact/bulk-email",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          ShowHeaderNotification(
            this.props.t("notifications:contact-form-sucessfully-sent"),
            "notification--confirmation"
          );
          this.clearAllInputs();
        } else {
          // Resolve the promise from the .json() first, in order to prevent passing a Promise to the handleErrorResponse-Function.
          let resolvedResponse = Promise.resolve(response.json());
          resolvedResponse.then((error) => this.handleErrorResponse(error));
        }
      })
      .catch(() => {
        this.notification = ShowHeaderNotification(
          this.props.t("notifications:unexpected-error"),
          "notification--alert"
        );
      })
      .finally(() => {
        // Scroll to the top, so the User sees the notification.
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      });
  }

  handleErrorResponse(error) {
    this.setInvalidInputValidationMessages(error.violations);
    this.notification = ShowHeaderNotification(
      createValidationErrorMessage(error.violations, this.props.t),
      "notification--alert"
    );
  }

  // Set validation-messages according to the violations recognized by public-api.
  setInvalidInputValidationMessages(violations) {
    const emailIsInvalid = violations.some(
      (violation) => violation.message === VIOLATIONS.INVALID_EMAIL
    );

    const ipIsInvalid = violations.some(
      (violation) => violation.message === VIOLATIONS.INVALID_IP
    );

    let cannotBeBlank = violations.filter(
      (violation) => violation.message === VIOLATIONS.MUST_NOT_BE_EMPTY
    );

    let cannotBeUnchecked = violations.filter(
      (violation) => violation.message === VIOLATIONS.MUST_BE_TRUE
    );

    let captchaInvalid = violations.some(
      (violation) => violation.message === VIOLATIONS.INVALID_CAPTCHA
    );

    let startDateInvalid = false;
    let endDateInvalid = false;
    if (!this.openedFromBounceLink) {
      startDateInvalid = violations.some(
        (violation) =>
          violation.message ===
            VIOLATIONS.STARTDATE_MUST_NOT_BE_GREATER_THAN_ENDDATE ||
          violation.message ===
            VIOLATIONS.TIMESTAMP_EMPTY_STARTDATE_ENDDATE_MUST_BE_FILLED
      );

      endDateInvalid = violations.some(
        (violation) =>
          violation.message ===
            VIOLATIONS.TIMESPAN_MUST_NOT_BE_MORE_THAN_TWO_DAYS ||
          violation.message ===
            VIOLATIONS.ENDDATE_MUST_NOT_BE_GREATER_THAN_TODAY
      );
    }

    if (emailIsInvalid) {
      this.emailRef.validationMessage = this.props.t(
        "notifications:invalid-email-address"
      );
    }

    if (ipIsInvalid) {
      this.ipRef.validationMessage = this.props.t("notifications:invalid-ip");
    }

    cannotBeBlank.forEach((violation) => {
      if (FIELDNAMES.includes(violation.fieldName)) {
        // deepcode ignore PrototypePollution: <Due to only permitting certain fieldNames here, this should not be a problem. It is on clients side anyway and response is from public-api, which can be considered a trusted-source.>
        this[violation.fieldName + "Ref"].validationMessage = this.props.t(
          "generalTerms:input-required"
        );
      }
    });

    cannotBeUnchecked.forEach((violation) => {
      if (FIELDNAMES.includes(violation.fieldName)) {
        // deepcode ignore PrototypePollution: <Due to only permitting certain fieldNames here, this should not be a problem. It is on clients side anyway and response is from public-api, which can be considered a trusted-source.>
        this[violation.fieldName + "Ref"].validationMessage = this.props.t(
          "generalTerms:checkbox-required"
        );
      }
    });

    if (captchaInvalid) {
      this.captchaValidationMessageRef.classList.remove("hidden");
    }

    if (startDateInvalid) {
      this.startDateRef.validationMessage = this.props.t(
        "notifications:invalid-start-date"
      );
    }

    if (endDateInvalid) {
      this.endDateRef.validationMessage = this.props.t(
        "notifications:invalid-end-date"
      );
    }
  }

  clearAllInputs() {
    for (var checkbox of this.checkboxRefs) {
      checkbox.validationMessage = "";
      checkbox.checked = false;
    }

    this.inputFieldRefs.forEach((inputField) => {
      inputField.value = "";
      inputField.validationMessage = "";
    });
  }

  loadExternalCaptcha() {
    if (globalThis.ENVS) {
      document.getElementById("g-recaptcha").dataset.sitekey =
        globalThis.ENVS.RECAPTCHA_SITE_KEY;
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }

  render() {
    const { t } = this.props;

    return (
      <div className="DelistingForm">
        <div className="container">
          <div className="margin-v-4">
            <h1 className="text-h1 sc-navy margin-v-4">{t("contact-form")}</h1>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <section>
                  <div
                    className="card card--message-info card--icon card--border card--rounded card--clickable"
                    aria-labelledby="Information"
                  >
                    <i
                      className="card__item-icon icon icon-046-information-circle icon--s3"
                      aria-hidden="true"
                    ></i>
                    <div className="card__body">
                      <Trans i18nKey="info-card-body" t={t}>
                        This form is intended for bulk senders of e-mails with
                        an external domain. If you, as the owner of a Bluewin.ch
                        or Bluemail.ch address, have problems receiving or
                        sending e-mails, please contact our customer support or
                        call
                        <a href={t("my-service-link")}>MyService</a>.
                      </Trans>
                    </div>
                  </div>
                  <div className="margin-top-2">
                    {t("typical-problems-for-sending-mails-to-bluewin")}

                    <ul className="list">
                      <li>{t("problem-1-for-sending-mails-to-bluewin")}</li>
                      <li>{t("problem-2-for-sending-mails-to-bluewin")}</li>
                    </ul>
                  </div>
                  <sdx-input-group type="checkbox">
                    <div className="row">
                      <div className="col">
                        <sdx-input-item
                          id="bounceHandling-checkbox"
                          ref={(bounceHandlingCheckbox) =>
                            (this.bounceHandlingConfirmationRef =
                              bounceHandlingCheckbox)
                          }
                          required
                          validationMessage=""
                        >
                          <Trans i18nKey="confirm-bounce-handling" t={t}>
                            I confirm that I clean up my recipient list after
                            each mailing by correct
                            <a href="https://postmaster.bluewin.ch/best-practices">
                              bounce handling
                            </a>
                            .
                          </Trans>
                        </sdx-input-item>
                      </div>
                    </div>
                    <div className="row margin-top-2">
                      <div className="col">
                        <sdx-input-item
                          id="doubleOptIn-checkbox"
                          ref={(doubleOptIn) =>
                            (this.doubleOptInconfirmationRef = doubleOptIn)
                          }
                          required
                          validationMessage=""
                        >
                          {t("confirm-double-opt-in-for-recipient-list")}
                        </sdx-input-item>
                      </div>
                    </div>
                    <div className="row margin-top-2">
                      <div className="col">
                        <sdx-input-item
                          id="gdprConfirmation-checkbox"
                          ref={(gdprConfirmation) =>
                            (this.gdprConfirmationRef = gdprConfirmation)
                          }
                          required
                          validationMessage=""
                        >
                          {t("confirm-dsgvo-confirmation-of-recipients")}
                        </sdx-input-item>
                      </div>
                    </div>
                  </sdx-input-group>
                  {
                    // If it was opened from bounce Email-Link, there is no need to show the Datepicker,
                    // as a timestamp is already passed by URL-Parameters
                    this.openedFromBounceLink ? (
                      <div />
                    ) : (
                      <div>
                        <div id="date-picker" className="row">
                          <div className="col">
                            <p className="margin-top-4">{t("date-range")}</p>
                            <sdx-input
                              id="start-date-picker"
                              type="date"
                              label={t("start-date")}
                              ref={(startDate) =>
                                (this.startDateRef = startDate)
                              }
                              onInput={this.onStartDateInput}
                              required
                              placeholder="e.g. 2000-01-31"
                            ></sdx-input>
                          </div>
                          <div className="col">
                            <sdx-input
                              id="end-date-picker"
                              type="date"
                              label={t("end-date")}
                              ref={(endDate) => (this.endDateRef = endDate)}
                              required
                              placeholder="e.g. 2000-01-31"
                            ></sdx-input>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  <div className="row margin-v-2">
                    <div className="col">
                      <sdx-select
                        label={t("select-kind-of-problem")}
                        className="margin-v-2"
                        id="problem-type-select"
                        ref={(problemTypeor) =>
                          (this.problemTypeRef = problemTypeor)
                        }
                        required
                      >
                        <sdx-select-option value="block" selected>
                          {t("problem-mails-being-blocked")}
                        </sdx-select-option>
                        <sdx-select-option value="spam">
                          {t("problem-mails-being-marked-as-spam")}{" "}
                        </sdx-select-option>
                        <sdx-select-option value="other">
                          {t("problem-other")}{" "}
                        </sdx-select-option>
                      </sdx-select>
                    </div>
                  </div>
                </section>
                <section>
                  <h2 className="text-h2 sc-navy">
                    {t("contact-information")}
                  </h2>
                  <p>
                    {t("enter-contact-information-of-technical-professional")}
                  </p>
                  <div className="row margin-bottom-2">
                    <div className="col">
                      <sdx-input
                        id="company"
                        label={t("company")}
                        ref={(companyInput) => (this.companyRef = companyInput)}
                        required
                      ></sdx-input>
                    </div>
                  </div>
                  <div className="row margin-bottom-2">
                    <div className="col">
                      <sdx-input
                        id="last-name"
                        label={t("last-name")}
                        ref={(lastNameInput) =>
                          (this.lastNameRef = lastNameInput)
                        }
                        required
                      ></sdx-input>
                    </div>
                  </div>
                  <div className="row margin-bottom-2">
                    <div className="col">
                      <sdx-input
                        id="first-name"
                        label={t("first-name")}
                        ref={(firstNameInput) =>
                          (this.firstNameRef = firstNameInput)
                        }
                        required
                      ></sdx-input>
                    </div>
                  </div>
                  <div className="row margin-bottom-2">
                    <div className="col">
                      <sdx-input
                        id="email-input-field"
                        label={t("email")}
                        ref={(emailInput) => (this.emailRef = emailInput)}
                        required
                        type="email"
                      ></sdx-input>
                    </div>
                  </div>
                </section>
                <section>
                  <h2 className="text-h2 sc-navy">
                    {t("technical-informations")}
                  </h2>
                  <div className="row margin-bottom-2">
                    <div className="col">
                      <sdx-input
                        id="ip-input-field"
                        label={t("public-ip-of-used-mailserver")}
                        ref={(publicIPInput) => (this.ipRef = publicIPInput)}
                        required
                      ></sdx-input>
                    </div>
                  </div>
                  <div
                    className="card card--message-info card--icon card--border card--rounded card--clickable"
                    aria-labelledby="Information"
                  >
                    <i
                      className="card__item-icon icon icon-046-information-circle icon--s3"
                      aria-hidden="true"
                    ></i>
                    <div className="card__body">
                      <Trans
                        i18nKey={
                          "in-case-other-addresses-are-affected-enter-network-range"
                        }
                        t={t}
                      >
                        If other addresser are affected, enter network range
                        like <code>192.168.1.0/24</code> or
                        <code>192.168.1.0-192.168.1.255</code>.
                      </Trans>
                    </div>
                  </div>
                  <div className="row margin-v-2">
                    <div className="col">
                      <sdx-input
                        label={t("extensive-description-of-problem")}
                        type="textarea"
                        maxlength={1000}
                        ref={(descriptionInput) =>
                          (this.commentRef = descriptionInput)
                        }
                        required
                      ></sdx-input>
                    </div>
                  </div>
                  <sdx-input-group
                    type="checkbox"
                    className="margin-bottom-2 display-block"
                  >
                    <sdx-input-item
                      className="margin-bottom-2"
                      ref={(declarationOfConsentCheckbox) =>
                        (this.declarationOfConsentRef =
                          declarationOfConsentCheckbox)
                      }
                      required
                    >
                      {t("declaration-of-consent")}
                      <div slot="description">
                        {t(
                          "i-am-content-that-swisscom-contacts-me-for-support"
                        )}
                      </div>
                    </sdx-input-item>
                  </sdx-input-group>
                </section>

                <div
                  className="g-recaptcha margin-top-2"
                  data-sitekey={
                    globalThis?.ENVS?.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY
                  }
                  id="g-recaptcha"
                ></div>
                <div
                  ref={(captchaValidationMessage) =>
                    (this.captchaValidationMessageRef =
                      captchaValidationMessage)
                  }
                  className="text-small margin-bottom-2 hidden"
                  style={{ color: "#d12" }}
                >
                  <sdx-icon
                    icon-name="icon-exclamation-mark"
                    aria-hidden="true"
                    class="hydrated"
                    size="2"
                  ></sdx-icon>
                  {t("generalTerms:captcha-required")}
                </div>

                <sdx-button
                  class="margin-top-2"
                  id="submitbutton"
                  label={t("generalTerms:submit")}
                  onClick={() => this.sendForm()}
                ></sdx-button>
              </div>
              <div className="margin-5" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation([
  "delistingForm",
  "generalTerms",
  "notifications",
])(DelistingForm);
