import React from "react";

export default function BigLoadingSpinner() {
  return (
    <div
      className="margin-0"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <sdx-loading-spinner
        size="large"
        sr-hint="Loading. Please wait."
      ></sdx-loading-spinner>
    </div>
  );
}
