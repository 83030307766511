import "./components/App.css";
import Footer from "./components/Footer.jsx";
import Header from "./components/Header.jsx";
import "@swisscom/sdx/dist/css/sdx.css";
import React from "react";
import SupportPage from "./components/SupportPage.jsx";
import BigLoadingSpinner from "./components/BigLoadingSpinner.jsx";
import ErrorBoundary from "./components/ErrorBoundary";
import { BrowserRouter } from "react-router-dom";
import resolvePathLanguage from "./helpers/resolvePathLanguage";

class App extends React.Component {
  async componentDidMount() {
    var userLang = navigator.language;
    const path = resolvePathLanguage(window.location.pathname, userLang);
    window.history.pushState({}, null, "/");
    window.history.pushState({}, null, path);

    // This is a Workaround, as we are unable to directly access process.env variables in React-Code at the moment.
    await fetch("/envs")
      .then((response) => response.json())
      .then((result) => (globalThis.ENVS = result))
      .catch((error) =>
        console.error("An error occured while fetching env-variables: " + error)
      );
  }
  render() {
    return (
      <div className="App">
        <div className="major-wrapper">
          <React.Suspense fallback={<BigLoadingSpinner />}>
            <div className="main-wrapper flex">
              <Header />
              <div className="page">
                <ErrorBoundary>
                  <BrowserRouter>
                    <SupportPage />
                  </BrowserRouter>
                </ErrorBoundary>
              </div>
              <Footer />
            </div>
          </React.Suspense>
        </div>
      </div>
    );
  }
}

export default App;
