function isIPValid(ip) {
  // The REGEX is taken from the public-api: https://git.swisscom.com/projects/BWMAIL/repos/public-api/browse/src/main/java/com/swisscom/bwmail/publicapi/validation/IpAddressValidator.java
  const ipRegex = new RegExp(
    "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
  );

  return ipRegex.test(ip);
}

function isEmailValid(email) {
  if (email === undefined || email === null) {
    return false;
  }
  let splitUpEmail = email.toLowerCase().split(/@/);
  let localPart = splitUpEmail[0];
  let domainPart = splitUpEmail[1];

  // The Regex is the same as in public-api. (javax.validation.constraints.Email)
  const localRegex = new RegExp(
    '([a-z0-9!#$%&\'*+/=?^_`{|}~\u0080-\uffff-]+|"([a-z0-9!#$%&\'*.(),<>\\[\\]:;  @+/=?^_`{|}~\u0080-\uffff-]|\\\\\\\\|\\\\\\")+")(\\.([a-z0-9!#$%&\'*+/=?^_`{|}~\u0080-\uffff-]+|"([a-z0-9!#$%&\'*.(),<>\\[\\]:;  @+/=?^_`{|}~\u0080-\uffff-]|\\\\\\\\|\\\\\\")+"))*'
  );
  const domainRegex = new RegExp(
    "([a-z\u0080-\uffff0-9!#$%&'*+/=?^_`{|}~]-*)*[a-z\u0080-\uffff0-9!#$%&'*+/=?^_`{|}~]+(\\.([a-z\u0080-\uffff0-9!#$%&'*+/=?^_`{|}~]-*)*[a-z\u0080-\uffff0-9!#$%&'*+/=?^_`{|}~]+)*|\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\]|\\[IPv6:(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))\\]"
  );

  return (
    localRegex.test(localPart) &&
    domainRegex.test(domainPart) &&
    splitUpEmail.length === 2 &&
    localPart.length > 0 &&
    domainPart.length > 0
  );
}

export { isEmailValid, isIPValid };
