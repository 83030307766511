import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React from "react";

export default function NotFound() {
  const { t } = useTranslation("generalTerms");
  return (
    <div id="not-found">
      <h1 className="text-h1 sc-navy">{t("not-found")}</h1>
      <Link to="/" className="link">
        {t("back-to-home")}
      </Link>
    </div>
  );
}
