import { VIOLATIONS } from "./constants";

export function createValidationErrorMessage(violations, translate) {
  let violationStrings = [];

  // Show an error, even when no violations are present.
  if (violations === null) {
    return translate("notifications:unexpected-error");
  }

  violations.forEach((violation) => {
    if (violation.message === VIOLATIONS.MUST_NOT_BE_BLANK) {
      let translatedFieldName = translate(
        `delistingForm:${violation.fieldname}`
      );
      let translatedViolation = translate("delistingForm:must-not-be-blank");

      let violationString = `${translatedFieldName} ${translatedViolation}`;
      violationStrings.push(violationString);
    } else if (violation.message === VIOLATIONS.INVALID_EMAIL) {
      violationStrings.push(translate("notifications:invalid-email-address"));
    } else if (violation.message === VIOLATIONS.INVALID_IP) {
      violationStrings.push(translate("notifications:invalid-ip"));
    } else if (violation.message === VIOLATIONS.MUST_BE_TRUE) {
      violationStrings.push(translate("notifications:must-be-checked"));
    } else if (
      violation.message ===
        VIOLATIONS.STARTDATE_MUST_NOT_BE_GREATER_THAN_ENDDATE ||
      violation.message ===
        VIOLATIONS.TIMESTAMP_EMPTY_STARTDATE_ENDDATE_MUST_BE_FILLED
    ) {
      violationStrings.push(translate("notifications:invalid-start-date"));
    } else if (
      violation.message ===
        VIOLATIONS.TIMESPAN_MUST_NOT_BE_MORE_THAN_TWO_DAYS ||
      violation.message === VIOLATIONS.ENDDATE_MUST_NOT_BE_GREATER_THAN_TODAY
    ) {
      violationStrings.push(translate("notifications:invalid-end-date"));
    } else if (violation.message === VIOLATIONS.INVALID_CAPTCHA) {
      violationStrings.push(translate("notifications:invalid-captcha"));
    } else {
      violationStrings.push(violation.message);
    }
  });

  return `${translate(
    "notifications:form-invalid-response"
  )} ${violationStrings.join(", ")}`;
}
