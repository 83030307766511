import "./App.css";
import "@swisscom/sdx/dist/css/sdx.css";
import React, { useState } from "react";
import LanguageSwitch from "./LanguageSwitch";
import { useTranslation } from "react-i18next";

function Header() {
  const [mobileMenuHidden, changeMobileMenuHidden] = useState(true);
  const { i18n } = useTranslation();

  return (
    <div className="Header" id="header">
      <nav className="nav">
        <div className="nav__level0">
          <div className="nav__mainnav ">
            <div className="container">
              <div className="nav__primary">
                <ul className="nav-items">
                  <li className="nav-item ">
                    <a
                      id="postmasterlink"
                      href="https://postmaster.bluewin.ch"
                      className="nav-link"
                    >
                      Postmaster
                    </a>
                  </li>
                  <li className="nav-item ">
                    <a
                      href="https://support.bluewin.ch"
                      className="nav-link nav-link--active"
                    >
                      <span className="text-underline">Support Page</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="nav__secondary">
                <ul className="nav-items">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://www.swisscom.ch/de/privatkunden.html"
                    >
                      <i className="icon icon-136-globe" aria-hidden="true"></i>
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://webmail.bluewin.ch">
                      <i
                        className="icon icon-051-message"
                        aria-hidden="true"
                      ></i>
                      Webmail
                    </a>
                  </li>
                  <li className="nav-item">
                    <i aria-hidden="true"></i>
                    <LanguageSwitch i18n={i18n} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="nav__primary nav__level1">
          <div className="container">
            <a href="/" className="logo">
              <span className="logo-lifeform" aria-label="Swisscom Logo" />
              <span className="logo-type">
                <img src="/assets/swisscom-logo-type.svg" alt="Swisscom" />
              </span>
            </a>
            <div className="nav__mainnav hidden-tablet-up">
              <div className="nav__secondary">
                <ul className="nav-items">
                  <li className="nav-item hidden-tablet-up">
                    <a
                      className={
                        "nav-icon nav-hamburger" +
                        (mobileMenuHidden ? "" : " is-active")
                      }
                      aria-label="Menu"
                      id="nav-hamburger"
                      onClick={() => {
                        changeMobileMenuHidden(!mobileMenuHidden);
                      }}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </a>
                  </li>
                </ul>
                <div
                  className={
                    "nav__primary " + (mobileMenuHidden ? "" : "is-open")
                  }
                >
                  <ul className="nav-items">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i aria-hidden="true"></i>
                        <LanguageSwitch i18n={i18n} />
                      </div>
                    </li>
                    <li className="nav-item ">
                      <a
                        href="https://postmaster.bluewin.ch"
                        className="nav-link nav-link--active"
                      >
                        <span className="text-underline">Postmaster</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="https://www.swisscom.ch/de/privatkunden.html"
                      >
                        <i
                          className="icon icon-136-globe margin-right-2"
                          aria-hidden="true"
                        ></i>
                        Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="https://webmail.bluewin.ch">
                        <i
                          className="icon icon-051-message margin-right-2"
                          aria-hidden="true"
                        ></i>
                        Webmail
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
