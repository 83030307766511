import { Enum } from "./Enum";

export const SUPPORTED_LANGUAGES = Object.freeze(["en", "de", "fr", "it"]);

// All possible validation errors for the delisting request.
// See: https://git.swisscom.com/projects/BWMAIL/repos/public-api/browse/src/main/java/com/swisscom/bwmail/publicapi/validation/ErrorMessage.java
export const VIOLATIONS = new Enum(
  "MUST_NOT_BE_EMPTY",
  "MUST_NOT_BE_NULL",
  "INVALID_IP",
  "INVALID_EMAIL",
  "MUST_BE_TRUE",
  "TIMESTAMP_EMPTY_STARTDATE_ENDDATE_MUST_BE_FILLED",
  "TIMESPAN_MUST_NOT_BE_MORE_THAN_TWO_DAYS",
  "ENDDATE_MUST_NOT_BE_GREATER_THAN_TODAY",
  "STARTDATE_MUST_NOT_BE_GREATER_THAN_ENDDATE",
  "TIMESTAMP_PREFILLED_STARTDATE_ENDDATE_MUST_BE_EMPTY",
  "TIMESTAMP_PREFILLED_SCCODE_AND_MAILID_MUST_NOT_BE_EMPTY",
  "INVALID_CAPTCHA"
);

export const PUBLIC_API_VERSION = Object.freeze(
  "application/vnd.bwmail.v1+json"
);

export const FIELDNAMES = Object.freeze([
  "bounceHandlingconfirmation",
  "doupleOptInConfirmation",
  "gdprConfirmation",
  "problemType",
  "company",
  "lastName",
  "firstName",
  "email",
  "ip",
  "comment",
  "declarationOfConsent",
  "captchaValidationMessage",
  "startDate",
  "endDate",
]);
