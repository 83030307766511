import { Buffer } from 'buffer';

export default function () {
  // Get the path.  (The part after the /)
  let path = window.location.pathname;

  // Get only the base64 encoded Params.
  let splitUpPath = path
    .split("/")
    // To handle trailing /
    .filter((string) => string.length > 0);
  // Needs to be done this way, since enzyme does not yet support .at(-1)
  let base64Parameters = splitUpPath[splitUpPath.length - 1];

  // Get "original" base64 by undoing the URL-encoding (mainly %3D -> =).
  let urlDecodedBase64Parameters = decodeURIComponent(base64Parameters);

  // Base64 decoding.
  let parametersString = Buffer.from(
    urlDecodedBase64Parameters,
    "base64"
  ).toString("utf-8");

  // Split the params and put them in the parameters-Object.
  let parameters = {};
  let parameterList = parametersString.split(";");
  parameterList.forEach((param) => {
    let keyValueSplitted = param.split("=");
    let key = keyValueSplitted[0];
    let value = keyValueSplitted[1];

    parameters[key] = value;
  });

  return parameters;
}
