import { Notification } from "@swisscom/sdx";

// Type is one of "" (default), "notification--confirmation" or "notification--alert"
export const ShowHeaderNotification = (
  notificationText,
  type = "",
  callback = undefined,
  cancelCallback = undefined
) => {
  try {
    let notification = Notification.showOnHeader(
      "header",
      notificationText,
      callback,
      cancelCallback,
      type
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    return notification;
  } catch (e) {
    console.error(
      `Something went wrong while trying to show the Notification in ShowHeaderNotification: ${e}`
    );
  }

  return;
};

export default ShowHeaderNotification;
