import App from "./App";
import React from "react";
import {
  defineCustomElements,
  applyPolyfills,
} from "@swisscom/sdx/dist/js/webcomponents/loader";
import ReactDOM from "react-dom";
import "@swisscom/sdx/dist/css/sdx.css";
import "./i18n.js";

ReactDOM.render(<App />, document.getElementById("root"));

applyPolyfills()
  .then(() => {
    defineCustomElements();
  })
  .catch((error) => console.error("Error while applying Polyfills: " + error));
